import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueClipboard from "vue-clipboard2";
// import 'lib-flexible/flexible.js'
import "./util/rem/rem";
import "swiper/swiper-bundle.css";
import * as moment from "@/api/filter";
import VueCropper from "vue-cropper";
import animated from "animate.css"; // npm install animate.css --save安装，在引入

Vue.use(animated);
Vue.use(VueCropper);
Object.keys(moment).forEach(key => {
  Vue.filter(key, moment[key]);
});
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
