import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      // 页面标题title
      title: '首页'
    },
    children: [
      {
        path: "/",
        name: "index",
        meta: {
          // 页面标题title
          title: '欢迎进入infortree官网'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/index/index.vue")
      },
      {
        path: "/connectus",
        name: "connectus",
        meta: {
          // 页面标题title
          title: '联系我们报告问题'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "connectus" */ "../views/connectUs/index.vue"
          )
      },
      {
        path: "/fortreeApi",
        name: "fortreeApi",
        meta: {
          // 页面标题title
          title: 'API'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "api" */ "../views/fortreeApi/index.vue")
      },
      {
        path: "/aboutus",
        name: "aboutus",
        meta: {
          // 页面标题title
          title: '关于我们'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "aboutus" */ "../views/aboutus/index.vue")
      },
      {
        path: "/problem",
        name: "problem",
        meta: {
          // 页面标题title
          title: '常见问题'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "problem" */ "../views/problem/index.vue")
      },
      {
        path: "/repository",
        name: "repository",
        meta: {
          // 页面标题title
          title: '知识库与帮助'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "repository" */ "../views/repository/index.vue"
          )
      },
      {
        path: "/prices",
        name: "prices",
        meta: {
          // 页面标题title
          title: '价格'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "repository" */ "../views/prices/index.vue"
          )
      },
      {
        path: "/register",
        name: "register",
        meta: {
          // 页面标题title
          title: '注册'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/index.vue"
          )
      },
      {
        path: "/login",
        name: "login",
        meta: {
          // 页面标题title
          title: '登入'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/login/index.vue")
      },
      {
        path: "/forget",
        name: "forget",
        meta: {
          // 页面标题title
          title: '忘记密码'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "forget" */ "../views/forget/index.vue")
      },
      {
        path: "/agreement",
        name: "agreement",
        meta: {
          // 页面标题title
          title: '服务条款与隐私协议'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "agreement" */ "../views/agreement/index.vue"
          )
      },
      {
        path: "/membership",
        name: "membership",
        meta: {
          // 页面标题title
          title: '会员关系'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/membership/index.vue"
          )
      }
    ]
  },
  {
    path: "/usercenter",
    name: "usercenter",
    component: () =>
      import(
        /* webpackChunkName: "usercenter" */ "../views/usercenter/index.vue"
      ),
    children: [
      {
        path: "/inbox",
        name: "inbox",
        meta: {
          // 页面标题title
          title: '收件箱'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/inbox/index.vue"
          )
      },
      {
        path: "/shocang",
        name: "shocang",
        meta: {
          // 页面标题title
          title: '收藏列表'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/shocang/index.vue"
          )
      },
      {
        path: "/soncompany",
        name: "soncompany",
        meta: {
          // 页面标题title
          title: '附属公司'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/soncompany/index.vue"
          )
      },
      {
        path: "/statistical",
        name: "statistical",
        meta: {
          // 页面标题title
          title: '统计数据'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/statistical/index.vue"
          )
      },
      {
        path: "/paymenthistory",
        name: "paymenthistory",
        meta: {
          // 页面标题title
          title: '支付记录'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/paymenthistory/index.vue"
          )
      },
      {
        path: "/payment",
        name: "payment",
        meta: {
          // 页面标题title
          title: '支付'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/payment/index.vue"
          )
      },
      {
        path: "/upgradevip",
        name: "upgradevip",
        meta: {
          // 页面标题title
          title: '升级到企业版'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/upgradevip/index.vue"
          )
      },
      {
        path: "/accountsetting",
        name: "accountsetting",
        meta: {
          // 页面标题title
          title: '账号设置'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../views/usercenter/accountsetting/index.vue"
          )
      },
      {
        path: "/edit",
        name: "edit",
        meta: {
          // 页面标题title
          title: '编辑手机页面'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ "../components/edit-iphone/index.vue"
          )
      }
    ]
  },
  {
    path: "/iphone",
    name: "iphone",
    meta: {
      // 页面标题title
      title: 'infortree'
    },
    component: () =>
      import(
        /* webpackChunkName: "usercenter" */ "../components/iphoneinfo/index.vue"
      )
  },
  {
    path: "/404",
    meta: {
      // 页面标题title
      title: '找不到了'
    },
    component: () =>
      import(/* webpackChunkName: "usercenter" */ "../views/unfound.vue")
  }
];

const router = new VueRouter({
  base: "/dist",
  // mode: "history",
  routes
});

export default router;
