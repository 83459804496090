import axios from "axios";
// const apiBaseURL = process.env.VUE_APP_API_URL || `${location.origin}/api`;
const apiBaseURL = process.env.VUE_APP_API_URL || `${location.origin}`;
const service = axios.create({
  baseURL: apiBaseURL,
  timeout: 10000 // 请求超时时间
});

// axios.defaults.withCredentials = true; // 携带cookie

// 请求拦截器
service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    let data = [];
    if (response.status === 200) {
      data = response.data;
    }
    return Promise.resolve(data);
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;
