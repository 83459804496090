import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    myArray: [
      {
        people: "cn",
        id: 1,
        name: "www.itxst.com",
        img: "../../util/images/index/icon_lj.png"
      },
      {
        people: "cn",
        id: 2,
        name: "www.itxst.com",
        img: "../../util/images/index/icon_lj.png"
      }
    ],
    background: { background: "#fff" }
  },
  mutations: {
    changeackg(state, item) {
      state.background = item;
    }
  },
  getters: {
    getbackg(state) {
      return state.background;
    }
  },
  actions: {},
  modules: {}
});
