import request from "@/api/request";
/*
 * 登录
 * */
export function Login(params) {
  return request({
    url: "api/user/login",
    method: "get",
    params
  });
}

/*
 *vip服务协议
 * */

export function apiQiCode(data) {
  return request({
    url: "api_qr_code",
    method: "post",
    data
  });
}
/*
 *商品列表
 * */

export function goodsList(data) {
  return request({
    url: "api_goods_list",
    method: "post"
  });
}
/*
 *注册
 * */

export function register(data) {
  return request({
    url: "api/user/register",
    method: "post",
    data
  });
}
/*
 *邮箱验证码发送
 * */

export function emssend(data) {
  return request({
    url: "api/ems/send",
    method: "post",
    data
  });
}
/*
 *手机验证码发送
 * */

export function iphonesend(data) {
  return request({
    url: "api/index/send_code",
    method: "post",
    data
  });
}
/*
 *手机验证码发送
 * */

export function iphonehsend(data) {
  return request({
    url: "api/index/hsend_code",
    method: "post",
    data
  });
}
/*
 *验证手机验证码
 * */

export function smscheck(data) {
  return request({
    url: "api/sms/check",
    method: "post",
    data
  });
}
/*
 *验证手机验证码
 * */

export function hsmscheck(data) {
  return request({
    url: "api/sms/hcheck",
    method: "post",
    data
  });
}
/*
 *验证邮箱验证码
 * */

export function emscheck(data) {
  return request({
    url: "api/ems/check",
    method: "post",
    data
  });
}
/*
 *文章列表
 * */

export function articlelist(data) {
  return request({
    url: "api/article/index",
    method: "post",
    data
  });
}

/*
 *文章详情
 * */

export function articleinfo(data) {
  return request({
    url: "api/article/list_one",
    method: "post",
    data
  });
}
/*
 *文章列表
 * */

export function articlelist1(data) {
  return request({
    url: "api/article/list",
    method: "post",
    data
  });
}
/*
 *付款记录
 * */

export function orderlist(data) {
  return request({
    url: "api_order_list",
    method: "post",
    data
  });
}
/*
 *首页
 * */

export function index() {
  return request({
    url: "api/article/shoye",
    method: "post"
  });
}
/*
 *重置密码
 * */

export function resetPwd(data) {
  return request({
    url: "api/user/reset_pwd",
    method: "post",
    data
  });
}
/*
 *协议
 * */

export function httptext(data) {
  return request({
    url: "api/article/info",
    method: "post",
    data
  });
}

/*
 *api
 * */

export function articleApi(data) {
  return request({
    url: "api/article/article_api",
    method: "post",
    data
  });
}
/*
 *会员关系页面
 * */

export function articleGuanxi(data) {
  return request({
    url: "api/article/article_guanxi",
    method: "post",
    data
  });
}
/*
 *关于我们页面
 * */

export function articleGuanyu(data) {
  return request({
    url: "api/article/article_guanyu",
    method: "post",
    data
  });
}

/*
 *收件箱
 * */

export function inbox(data) {
  return request({
    url: "api/member/inbox",
    method: "post",
    data
  });
}

/*
 *用户信息
 * */

export function userinfo(data) {
  return request({
    url: "api/member/index",
    method: "post",
    data
  });
}
/*
 *上传文件
 * */

export function upload(data) {
  return request({
    url: "api/index/upload",
    method: "post",
    data,
    contentType: false,
    processData: false,
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });
}

/*
 *时区
 * */

export function timezone(data) {
  return request({
    url: "api/index/timezone",
    method: "post",
    data
  });
}
/*
 *账户设置
 * */

export function profile(data) {
  return request({
    url: "api/index/profile",
    method: "post",
    data
  });
}
/*
 *关联账户
 * */

export function useraffiliated(data) {
  return request({
    url: "api/member/user_affiliated",
    method: "post",
    data
  });
}

/*
 *关联账户查询
 * */

export function useraffiliatedlist(data) {
  return request({
    url: "api/member/user_affiliated_list",
    method: "post",
    data
  });
}
/*
 *POST
下级信息
 * */

export function tidlist(data) {
  return request({
    url: "api/member/tid_list",
    method: "post",
    data
  });
}
/*
 *POST
下级信息
 * */

export function withdraw(data) {
  return request({
    url: "api/member/withdraw",
    method: "post",
    data
  });
}

/*
 *POST
下级信息
 * */

export function payorder(data) {
  return request({
    url: "api/goods/pay_order",
    method: "post",
    data
  });
}
/*
 *POST
下级信息
 * */

export function wxlogin1(data) {
  return request({
    url: "index/index/wechat",
    method: "post",
    data
  });
}
/*
 *POST
下级信息
 * */

export function favoritelists(data) {
  return request({
    url: "api/stationery/favorite_lists",
    method: "post",
    data
  });
}
