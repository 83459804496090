<template>
  <div class="home">
    <el-container>
      <el-header height="120px"
        ><div class="nav">
          <div class="contanier">
            <el-row :gutter="20">
              <el-col :span="11">
                <div class="ailgn-center">
                  <el-image
                    @click="goback"
                    style="width: 195px; height: 46px"
                    :src="require('@/util/images/index/pic_logo_head.png')"
                    fit="contain"
                  ></el-image></div
              ></el-col>
              <el-col :span="13"
                ><div class="ailgn-center">
                  <el-menu
                    :default-active="activeIndex"
                    mode="horizontal"
                    :router="true"
                    @select="handleSelect"
                    text-color="#fff"
                    active-text-color="#ffd04b"
                  >
                    <el-menu-item index="/">特征</el-menu-item>
                    <el-menu-item index="/prices">价格</el-menu-item>
                    <el-menu-item index="/repository">帮助</el-menu-item>
                    <el-menu-item index="/membership">会员关系</el-menu-item>
                    <el-menu-item index="/register">
                      <span class="btn">注册</span>
                    </el-menu-item>
                    <el-menu-item index="/login">
                      <span class="btn">登录</span>
                    </el-menu-item>
                  </el-menu>
                </div></el-col
              >
            </el-row>
          </div>
        </div></el-header
      >
      <el-main>
        <router-view />
      </el-main>
      <el-footer height="310px">
        <div class="contanier">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="footer-logo">
                <el-image
                  style="width: 136px; height: 32px"
                  :src="require('@/util/images/index/pic_logo_bottom.png')"
                  fit="contain"
                ></el-image>
                <p>
                  有问题吗？给我们发送电子邮件：<br />
                  {{ email.value }}
                </p>
              </div>
            </el-col>
            <el-col :span="10"
              ><div class="footer-list">
                <ul v-for="(item, index) in footerlist" :key="index">
                  <li
                    v-for="(item1, index1) in item"
                    @click="target(item1.path)"
                    :key="index1"
                  >
                    {{ item1.name }}
                  </li>
                </ul>
              </div></el-col
            >
            <el-col :span="8"
              ><p class="copy">
                @ 2021 | 中金颐和（北京）科技有限公司 <br />
                <a style="color:#fff" href="https://beian.miit.gov.cn/"
                  >京ICP备2021015530号-1</a
                >
              </p></el-col
            >
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { index } from "../api/userapi";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      email: "",
      activeIndex: "/",
      footerlist: [
        [
          { name: "关于我们", path: "/aboutus" },
          { name: "价格", path: "/prices" },
          { name: "特征", path: "/" }
        ],
        [
          { name: "知识库", path: "/repository" },
          { name: "常问问题", path: "/problem" },
          // { name: "产品变更", path: "" },
          { name: "报告问题", path: "/connectus" }
        ],
        [
          { name: "服务条款", path: "/agreement?type=2" },
          { name: "隐私政策", path: "/agreement?type=1" },
          { name: "会员计划", path: "/membership" },
          { name: "API", path: "/fortreeApi" }
        ]
      ]
    };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    goback() {
      this.$router.push("/");
    },
    getinfo() {
      index()
        .then(res => {
          this.email = res.config;
        })
        .catch();
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    target(path) {
      this.$router.push(path);
    }
  }
};
</script>
<style lang="less" scoped>
.el-main {
  padding: 0;
  min-height: 700px;
  background: #f5f7f9;
}
.el-header {
  padding: 0;
}
.nav {
  height: 120px;
  background: linear-gradient(270deg, #009fda 0%, #00257a 100%);
}
.ailgn-center {
  display: flex;
  align-items: center;
  height: 120px;
}
.el-menu {
  background-color: transparent;
}
.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0px;
  color: #fff;
}
.el-menu--horizontal > .el-menu-item {
  color: #fff;
}
.btn {
  width: 92px;
  height: 38px;
  background: #1166a5;
  border-radius: 4px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
}
.el-footer {
  background: #141618;
  padding-top: 100px;
}
.footer-logo > p {
  margin-top: 10px;
  color: #fff;
  text-align: left;
  font-weight: 200;
  font-size: 12px;
  line-height: 16px;
}
ul li {
  font-size: 14px;
  font-weight: 200;
  color: #ffffff;
  line-height: 30px;
  list-style: none;
  cursor: pointer;
}
.footer-list {
  display: flex;
  justify-content: space-around;
}
.copy {
  text-align: right;
  font-size: 14px;
  font-weight: 200;
  color: #ffffff;
  line-height: 30px;
}
/deep/.el-menu-item {
  background: transparent;
}
/deep/.el-menu--horizontal > ul > li:hover {
  background: transparent !important;
}
/deep/.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: transparent !important;
}
</style>
